import Vue from 'vue';
import $http from '../utils/http';
import * as Cookies from 'js-cookie';

Vue.use(Cookies);

const getCpByUserID = (token, userID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/by-user-id/${userID}`, null, 'GET', header);
};
const getContentProviders = (token, companyID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/${companyID}`, null, 'GET', header);
};

const getContentProviderByID = (token, contentProviderId) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/item/${contentProviderId}`, null, 'GET', header);
};

const getContentProviderAndPricePlanById = (token, contentProviderID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/content-provider/price-plan/${contentProviderID}`, null, 'GET', header);
};

const createContentProvider = (token, contentProviderData) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider`, contentProviderData, 'POST', header);
};

const updateContentProvider = (token, contentProviderData, contentProviderID) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/${contentProviderID}`, contentProviderData, 'PUT', header);
};

const forceUpdateContentProvider = (token, contentProviderData, contentProviderID) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/update/${contentProviderID}`, contentProviderData, 'PUT', header);
};

const duplicateContentProvider = (token, contentProviderData) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/duplicate`, contentProviderData, 'POST', header);
};

const purchaseAppUsers = (token, contentProviderData, contentProviderID, count) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/update/appusers/${contentProviderID}/${count}`, contentProviderData, 'PUT', header);
};
const purchaseOffAppTextCredits = (token, contentProviderData, contentProviderID, count) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/update/offapptextcredits/${contentProviderID}/${count}`, contentProviderData, 'PUT', header);
};

// route is protected, only client admins can access
const freeAppUsers = (token, contentProviderID, count) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/update/appusers/free-purchase/${contentProviderID}/${count}`, {}, 'PUT', header);
};
// route is protected, only client admins can access
const freeOffAppTextCredits = (token, contentProviderID, count) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/update/offapptextcredits/free-purchase/${contentProviderID}/${count}`, {}, 'PUT', header);
};

const upgradePricePlan = (token, contentProviderData, contentProviderID, newPricePlanId) => {
  const header = token ? { 'Content-Type': 'application/json', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/update/updatepriceplan/${contentProviderID}/${newPricePlanId}`, contentProviderData, 'PUT', header);
};

const updateContentProviderStatus = (token, contentProviderData, contentProviderID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/status/${contentProviderID}`, contentProviderData, 'PUT', header);
};

const deleteContentProvider = (token, contentProviderID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/${contentProviderID}`, null, 'DELETE', header);
};

const getCPCategories = (token, companyID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/categories/${companyID}`, null, 'GET', header);
};

const getCategoryByID = (token, categoryID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/categories/category/${categoryID}`, null, 'GET', header);
};

const createCPCategory = (token, categoryData) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/categories`, categoryData, 'POST', header);
};

const updateCPCategory = (token, categoryData, categoryID) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/categories/category/${categoryID}`, categoryData, 'PUT', header);
};

const deleteCPCategory = (token, categoryID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/categories/${categoryID}`, null, 'DELETE', header);
};

const updateCPCategoryGroup = (token, formData, categoryID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/categories/category/group/${categoryID}`, formData, 'PUT', header);
};

const getCPTypes = (token, companyID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/types/${companyID}`, null, 'GET', header);
};

const getTypeByID = (token, typeID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/types/type/${typeID}`, null, 'GET', header);
};

const createCPType = (token, typeData) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/types`, typeData, 'POST', header);
};

const getAssociatedCPByTypeID = (token, typeID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/types/cpByType/${typeID}`, null, 'GET', header);
};

const updateCPType = (token, typeData, typeID) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/types/type/${typeID}`, typeData, 'PUT', header);
};

const deleteCPType = (token, typeID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/types/${typeID}`, null, 'DELETE', header);
};

const getCPGroups = (token, companyID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/groups/${companyID}`, null, 'GET', header);
};

const getGroupByID = (token, groupID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/groups/group/${groupID}`, null, 'GET', header);
};

const createCPGroup = (token, groupData) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/groups`, groupData, 'POST', header);
};

const updateCPGroup = (token, groupData, groupID) => {
  const header = token ? { 'Content-Type': 'multipart/form-data', authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/groups/group/${groupID}`, groupData, 'PUT', header);
};

const deleteCPGroup = (token, groupID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/groups/${groupID}`, null, 'DELETE', header);
};

const getUnionTypes = (token, companyID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/union-type/${companyID}`, null, 'GET', header);
};

const getUnionTypeByID = (token, unionTypeID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/union-type/type/${unionTypeID}`, null, 'GET', header);
};

const createUnionType = (token, unionTypeData) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/union-type`, unionTypeData, 'POST', header);
};

const updateUnionType = (token, unionTypeData, unionTypeID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/union-type/type/${unionTypeID}`, unionTypeData, 'PUT', header);
};

const deleteUnionType = (token, unionTypeID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/union-type/${unionTypeID}`, null, 'DELETE', header);
};

const getPackages = (token, companyID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/packages/${companyID}`, null, 'GET', header);
};

const getPackageByID = (token, packageID) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/packages/package/${packageID}`, null, 'GET', header);
};

const createPackage = (token, packageData) => {
  const header = token ? { authorization: token, 'x-company-header': Cookies.get('companyID') } : null;
  return $http(`/content-provider/packages`, packageData, 'POST', header);
};

const updatePackage = (token, packageData, packageID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/packages/package/${packageID}`, packageData, 'PUT', header);
};

const deletePackage = (token, packageID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/content-provider/packages/${packageID}`, null, 'DELETE', header);
};

const getFieldNameForUserGroupsDuringRegistration = (token, contentProviderID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/settings/channel/usergroups/group-field/${contentProviderID}`, null, 'GET', header);
};

const setFieldNameForUserGroupsDuringRegistration = (token, groupFieldData, contentProviderID) => {
  const header = token ? { authorization: token } : null;
  const body = { ...groupFieldData, contentProviderID };
  return $http(`/settings/channel/usergroups/group-field`, body, 'POST', header);
};

const uploadPreApprovalFile = (token, fileData, contentProviderID) => {
  const header = token ? { authorization: token } : null;
  const body = { ...fileData, contentProviderID };
  return $http(`/settings/channel/pre-approval`, body, 'POST', header);
};
const getPreApprovalFile = (token, contentProviderID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/settings/channel/pre-approval/${contentProviderID}`, null, 'GET', header);
};
const deletePreApprovalFile = (token, contentProviderID) => {
  const header = token ? { authorization: token } : null;
  return $http(`/settings/channel/pre-approval/${contentProviderID}`, null, 'DELETE', header);
};

export {
  getContentProviders,
  getContentProviderByID,
  getContentProviderAndPricePlanById,
  createContentProvider,
  updateContentProvider,
  forceUpdateContentProvider,
  purchaseAppUsers,
  purchaseOffAppTextCredits,
  upgradePricePlan,
  updateContentProviderStatus,
  deleteContentProvider,
  getCPCategories,
  getCategoryByID,
  createCPCategory,
  updateCPCategory,
  deleteCPCategory,
  updateCPCategoryGroup,
  getAssociatedCPByTypeID,
  getCPTypes,
  getTypeByID,
  createCPType,
  updateCPType,
  deleteCPType,
  getCPGroups,
  getGroupByID,
  createCPGroup,
  updateCPGroup,
  deleteCPGroup,
  getUnionTypes,
  getUnionTypeByID,
  createUnionType,
  updateUnionType,
  deleteUnionType,
  getPackages,
  getPackageByID,
  createPackage,
  updatePackage,
  deletePackage,
  freeOffAppTextCredits,
  freeAppUsers,
  getCpByUserID,
  duplicateContentProvider,
  getFieldNameForUserGroupsDuringRegistration,
  setFieldNameForUserGroupsDuringRegistration,
  uploadPreApprovalFile,
  getPreApprovalFile,
  deletePreApprovalFile,
};
